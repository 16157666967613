<template>
  <div v-if="data?._id" class="flex flex-col border border-bordercolor rounded-md h-36 p-3 cursor-pointer" @click="onClick">
    <div class="flex justify-between">
      <img :src="data?.payment_gateway_method?.payment_gateway?.image_url" alt="" class="w-20" />
      <span class="flex flex-row items-center space-x-2">
        <img :src="data?.payment_gateway_method?.payment_method?.image_url" alt="" class="h-7" />
        <p class="text-xs">
          {{ data?.payment_gateway_method?.payment_method?.name }}
        </p>
      </span>
    </div>
    <div class="flex-auto"></div>
    <div class="flex justify-between">
      <div class="flex space-x-2 items-center">
        <p class="text-gray-400 text-sm">
          {{ $t('payment.settings.payment_provider.channel_used') }}
        </p>
        <div>
          <app-logo-senangpay class="mb-1" v-if="data?.payment_gateway_method?.channel == PAYMENT_CHANNEL.SENANG_PAY" />
          <app-logo-m1pay class="h-3" v-else-if="data?.payment_gateway_method?.channel == PAYMENT_CHANNEL.M1PAY" />
          <app-logo-gobiz class="w-20" v-else-if="data?.payment_gateway_method?.channel == PAYMENT_CHANNEL.GO_BIZ" />
          <app-logo-stripe class="h-5" v-else-if="data?.payment_gateway_method?.channel == PAYMENT_CHANNEL.STRIPE" />
          <app-logo-iserve class="h-5" v-else-if="data?.payment_gateway_method?.channel == PAYMENT_CHANNEL.ISERVE" />
          <p class="text-sm" v-else>{{ data?.payment_gateway_method?.channel ?? "" }}</p>
        </div>
      </div>
      <div class="flex space-x-2 items-center">
        <p class="text-gray-500 text-sm">{{ $t('general.status') }} :</p>
        <p class="text-gray-400 text-sm uppercase">{{ data?.payment_gateway_application_status?.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import PAYMENT_CHANNEL from '@/utils/const/payment_channel'

export default {
  data() {
    return {
      PAYMENT_CHANNEL: PAYMENT_CHANNEL,
    }
  },
  props: ['data'],
  methods: {
    onClick(event) {
      this.$emit('onClick', this.data)
    }
  }
}
</script>